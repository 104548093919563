<template>
  <div>
    <div class="navWrapper">
      <nav class="nav">
        <router-link :to="'/pocetna'"><img class="navBarLogo" src="../Assets/navbar-logo.webp" alt="emarket poljopijaca logo" /></router-link>
        <ul class="desktopNavItems">
          <li :class="selectedPage === 0 ? 'activeTab' : null">
            <router-link :to="'/pocetna'"><v-icon :id="selectedPage === 0 ? 'activeIcon' : 'defaultIcon'">mdi-home</v-icon>Početna</router-link>
          </li>

          <li :class="selectedPage === 1 ? 'activeTab' : null">
            <router-link :to="'/pretraga-oglasa'"><v-icon :id="selectedPage === 1 ? 'activeIcon' : 'defaultIcon'">mdi-magnify</v-icon>Pretraga oglasa</router-link>
          </li>

          <!-- <li :class="selectedPage === 2 ? 'activeTab' : null" v-if="isLoggedIn" @click="() => this.$router.push('/poruke')">
            <v-icon :id="selectedPage === 2 ? 'activeIcon' : 'defaultIcon'">mdi-message</v-icon>Poruke
          </li> -->

          <li v-if="!isLoggedIn" @click="showSignIn = true"><v-icon color="white">mdi-login</v-icon>Uloguj se</li>
          <li :class="selectedPage === 5 ? 'activeTab' : null" v-if="!isLoggedIn">
            <router-link :to="'/registracija'"><v-icon :id="selectedPage === 5 ? 'activeIcon' : 'defaultIcon'">mdi-account-plus</v-icon>Registruj se</router-link>
          </li>

          <li class="accented">
            <router-link :to="'/dodaj-oglas'"><v-icon color="black">mdi-plus-circle</v-icon>Dodaj oglas</router-link>
          </li>

          <li :class="selectedPage === 4 ? 'activeTab' : null" v-if="isLoggedIn">
            <router-link :to="'/moj-nalog'"><v-icon :id="selectedPage === 4 ? 'activeIcon' : 'defaultIcon'">mdi-account-circle</v-icon>{{ this.profileTitle }}</router-link>
          </li>

          <li v-if="isLoggedIn && currentLoggedUser.type == 1">
            <router-link :to="'/admin/korisnici'"><v-icon color="white">mdi-account-cog</v-icon>Admin</router-link>
          </li>

          <li v-if="isLoggedIn" @click="LogOut"><v-icon color="white">mdi-logout</v-icon>Odjavi se</li>
        </ul>
      </nav>

      <nav class="adminNav" v-if="isLoggedIn && currentLoggedUser.type == 1">
        <ul class="desktopNavItems">
          <li :class="selectedPage === 6 ? 'activeTab' : null">
            <router-link :to="'/admin/korisnici'"><v-icon :id="selectedPage === 6 ? 'activeIcon' : 'defaultIcon'">mdi-account-group</v-icon>Korisnici</router-link>
          </li>
          <li :class="selectedPage === 7 ? 'activeTab' : null">
            <router-link :to="'/admin/kategorije'"><v-icon :id="selectedPage === 7 ? 'activeIcon' : 'defaultIcon'">mdi-folder-multiple</v-icon>Kategorije</router-link>
          </li>
          <li :class="selectedPage === 8 ? 'activeTab' : null">
            <router-link :to="'/admin/oglasi'"><v-icon :id="selectedPage === 8 ? 'activeIcon' : 'defaultIcon'">mdi-group</v-icon>Oglasi</router-link>
          </li>
          <li :class="selectedPage === 9 ? 'activeTab' : null">
            <router-link :to="'/admin/prijavljeni-oglasi'"><v-icon :id="selectedPage === 9 ? 'activeIcon' : 'defaultIcon'">mdi-alert-circle</v-icon>Prijavljeni oglasi</router-link>
          </li>
        </ul>
      </nav>

      <SignInDialog v-model="showSignIn" />
    </div>
    <div class="mobileMenu">
      <ul>
        <li v-if="!isLoggedIn || isLoggedIn">
          <router-link :to="'/pocetna'"><v-icon :id="selectedPage === 0 ? 'activeIcon' : 'defaultIcon'">mdi-home</v-icon></router-link>
        </li>
        <li v-if="!isLoggedIn || isLoggedIn">
          <router-link :to="'/pretraga-oglasa'"><v-icon :id="selectedPage === 1 ? 'activeIcon' : 'defaultIcon'">mdi-magnify</v-icon></router-link>
        </li>
        <li v-if="!isLoggedIn">
          <router-link :to="'/dodaj-oglas'"><v-icon :id="selectedPage === 3 ? 'activeIcon' : 'defaultIcon'">mdi-plus-circle</v-icon></router-link>
        </li>
        <!-- <li v-if="isLoggedIn" @click="() => this.$router.push('/poruke')"><v-icon :id="selectedPage === 2 ? 'activeIcon' : 'defaultIcon'">mdi-message</v-icon></li> -->
        <li v-if="isLoggedIn">
          <router-link :to="'/dodaj-oglas'"><v-icon :id="selectedPage === 3 ? 'activeIcon' : 'defaultIcon'">mdi-plus-circle</v-icon></router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link :to="'/moj-nalog'"><v-icon :id="selectedPage === 4 ? 'activeIcon' : 'defaultIcon'">mdi-account-circle</v-icon></router-link>
        </li>
        <li v-if="!isLoggedIn" id="defaultIcon" @click="showSignIn = true"><v-icon color="white">mdi-login</v-icon></li>
        <li v-if="isLoggedIn" id="defaultIcon" @click="LogOut"><v-icon color="white">mdi-logout</v-icon></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SignInDialog from "./SignInDialog.vue";

export default {
  data() {
    return {
      appTitle: "Poljo pijaca",
      profileTitle: "",
      showSignIn: false,
      dropDownMenu: [{ title: "Početna", link: "/pocetna" }],
      selectedPage: 0,
    };
  },
  methods: {
    addProduct() {
      this.$router.push("/dodaj-oglas");
    },
    ...mapActions(["LogOut"]),
    async logOut() {
      await this.LogOut();
    },
    checkPage(page) {
      switch (page) {
        case "/":
          this.selectedPage = 0;
          break;
        case "/pocetna":
          this.selectedPage = 0;
          break;
        case "/pretraga-oglasa":
          this.selectedPage = 1;
          break;
        // case "/poruke":
        //   this.selectedPage = 2;
        //   break;
        case "/dodaj-oglas":
          this.selectedPage = 3;
          break;
        case "/moj-nalog":
          this.selectedPage = 4;
          break;
        case "/registracija":
          this.selectedPage = 5;
          break;
        case "/admin/korisnici":
          this.selectedPage = 6;
          break;
        case "/admin/kategorije":
          this.selectedPage = 7;
          break;
        case "/admin/oglasi":
          this.selectedPage = 8;
          break;
        case "/admin/prijavljeni-oglasi":
          this.selectedPage = 9;
          break;
        case "/prijava":
          this.selectedPage = 10;
          break;
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },
  components: {
    SignInDialog,
  },
  watch: {
    currentLoggedUser: {
      handler() {
        this.checkPage(this.$route.path);
        this.profileTitle = ` ${this.currentLoggedUser?.firstName} ${this.currentLoggedUser?.lastName}`;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.navBarLogo {
  height: 3rem;
}
.activeTab {
  background-color: #fff;
  color: #0d5332;
  a {
    color: #137547 !important;
  }
  #activeIcon {
    a {
      text-decoration: none !important;
    }
    color: #137547;
  }
}
.adminNav {
  background-color: #137547;
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1920px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  #defaultIcon {
    color: #fff !important;
  }
}

.adminNav .desktopNavItems {
  padding: 0;
  list-style-type: none;
  color: #fff;
  display: none;
}

.adminNav ul li {
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  i {
    margin: 0 0.5rem;
  }
}

.adminNav ul li:hover {
  background-color: #ffffffaa;
}

.adminNav ul .accented {
  color: #137547;
  background-color: #ffffff;
}

.adminNav ul a {
  text-decoration: none;
  color: #fff;
}

.title {
  color: whitesmoke;
}

.mobileMenu {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  background-color: #137547;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;

  #activeIcon {
    color: #137547;
    padding: 0.4rem;
    background-color: #fff;
    border-radius: 50%;
  }
  #defaultIcon {
    color: #fff !important;
    padding: 0.4rem;
  }

  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    list-style-type: none;
    li {
      margin: 1rem auto;
      cursor: pointer;
      a {
        text-decoration: none;
      }
    }
  }
}

.menuBtn {
  font-weight: bold;
  text-transform: none;
}

.navWrapper {
  background-color: #137547;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.nav {
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1920px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .desktopNavItems {
  padding: 0;
  list-style-type: none;
  color: #fff;
  display: none;
  #defaultIcon {
    color: #fff !important;
  }
}

.nav ul li {
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  i {
    margin: 0 0.5rem;
  }
}

.nav ul li:hover {
  background-color: #ffffff66;
}

.nav ul .accented {
  a {
    color: #137547;
  }
  color: #137547;
  background-color: #ffffff;
  i {
    color: #137547 !important;
  }
  &:hover {
    color: #fff !important;
    i {
      color: #fff !important;
    }
  }
}
.nav ul .addad {
  color: #1c7947;
  border: 1px solid black;
  background-color: whitesmoke;
}
.nav ul .addad:hover {
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
}

.nav ul a {
  text-decoration: none;
  color: #fff;
}

.nav .searchBar {
  max-width: 500px;
  display: none;
}

.navAccentButton {
  background-color: #efefef;
  color: #000;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1140px) {
  .mobileMenu {
    display: none;
  }
  .desktopNavItems {
    display: inline-flex !important;
  }
  .searchBar {
    display: block !important;
  }
}
</style>
