<template>
  <div>
    <div>
      <NavBar />
      <div class="heroImage">
        <div class="heroText">
          <div class="heroDescription">
            <h1>POLJOPRIVREDNA PIJACA</h1>
            <p class="heroDescription">
              Platforma za internet prodaju poljoprivrednih mašina, mehanizacije, opreme, stoke, voća, povrća i ostalih proizvoda. Iskoristite naš sajt da oglasite svoj proizvod
              ili stupite u kontakt sa ostalim oglašivačima.
            </p>
            <button class="allProductsButton" @click="$router.push('/pretraga-oglasa')">Pretraga oglasa<v-icon>mdi-magnify</v-icon></button>
          </div>
        </div>
      </div>
      <h2 class="categoriesTitle">Kategorije<v-icon>mdi-label-variant</v-icon></h2>
      <div class="categoryWrapper">
        <div class="categorySlider">
          <div v-for="category in allCategories" :key="category.id" class="categoryCard">
            <div class="categoryName">
              <h2>{{ category.name }}</h2>
            </div>
            <div v-for="subCategory in category.subCategories" :key="subCategory.id" class="categoryListWrapper">
              <h3 @click="goToCategoryPage(subCategory)" class="categoryList">{{ subCategory.name }}</h3>
            </div>
          </div>
          <!-- <div @click="() => this.$router.push('/pretraga-oglasa')" class="categoryCard">
            <div class="allCategories">
              <h3>Svi oglasi</h3>
              <v-icon color="grey">mdi-arrow-right</v-icon>
            </div>
          </div> -->
        </div>
      </div>
      <div class="contentWrapper">
        <v-overlay :value="loading">
          <v-progress-circular indeterminate color="#137547"></v-progress-circular>
        </v-overlay>
        <h2 class="newProductsTitle">Najnoviji oglasi<v-icon>mdi-arrow-up</v-icon></h2>
        <div class="newItemsWrapper">
          <div @click="goToPage(product)" v-for="product in allLatestProducts.slice(0, 16)" :key="product.id" class="itemCard">
            <img :src="product.mainImgName ? `https://api.poljopijaca.rs/` + product.mainImgName : noImage" alt="" />
            <div class="itemDetails">
              <h3 class="itemTitle">{{ product.title }}</h3>
              <p>
                {{ product.description == null ? "Ovaj oglas nema opis." : product.description == "" ? "Ovaj oglas nema opis." : product.description.slice(0, 40) + "..." }}
              </p>
              <div class="priceCategoryWrapper">
                <div class="categoryTag"><v-icon>mdi-tag</v-icon> {{ product.subCategory.name.toUpperCase() }}</div>
                <div class="priceTag">
                  <v-icon>mdi-cash</v-icon>
                  {{
                    product.price > 0
                      ? `${product.price} RSD`
                      : product.paymentOption == 1
                      ? "Dogovor"
                      : product.paymentOption == 2
                      ? "Kontakt"
                      : product.paymentOption == 3
                      ? "Pozvati"
                      : product.paymentOption == 4
                      ? "Besplatno"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="allProductsButton" @click="$router.push('/pretraga-oglasa')">Pogledaj sve oglase<v-icon>mdi-arrow-right</v-icon></button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../Components/NavBar.vue";
import noImage from "../Assets/noimg.webp";
import Footer from "../Components/Footer.vue";

export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      noImage,
      allCategories: [],
      allLatestProducts: [],
      loading: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },
  created() {
    this.getCategories();
    this.getLatestProducts();
  },
  methods: {
    async getCategories() {
      axios.get("Category").then((resp) => {
        this.allCategories = resp.data.slice(0, 7);
      });
    },
    async getLatestProducts() {
      this.loading = true;
      axios
        .get("Product/LastProducts?n=16")
        .then((resp) => {
          this.allLatestProducts = resp.data;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    goToPage(product) {
      this.$router.push("/oglas/" + product.id);
    },
    goToCategoryPage(subCategory) {
      this.$router.push("/pretraga-oglasa/" + subCategory.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.allProductsButton {
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  background-color: #137547;
  border-radius: 4px;
  color: #fff;
  i {
    color: #fff;
    margin-left: 0.5rem;
  }
}
.heroImage {
  margin: 0 auto;
  max-width: 1920px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../Assets/hero-image.jpg");
  background-size: cover;
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.3);
  .heroText {
    padding: 2rem;
    background-size: cover;
    color: #fff;
    text-align: justify;
  }
  .search {
    width: 100%;
    max-width: 400px;
  }

  .allProductsButton {
    display: flex;
    margin: 0 auto;
    transition: 0.2s ease-in-out;
    animation: heroSearchButton 3s infinite;
    @keyframes heroSearchButton {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
    &:hover {
      transform: scale(1.02);
      background-color: white;
      color: #137547;
      i {
        color: #137547;
      }
    }
  }
}
.contentWrapper {
  max-width: 1440px;
  margin: 0rem auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .newProductsTitle {
    text-align: center;
    margin-bottom: 0.5rem;
    color: #137547;
    i {
      color: #137547;
    }
  }

  .newItemsWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .itemCard {
      width: 100%;
      margin: 0.25rem;
      border-radius: 4px;
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      img {
        object-fit: fill;
        width: 33%;
      }
      .itemDetails {
        width: 100%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        .itemTitle {
          text-align: left;
          color: #137547;
        }
        a {
          text-decoration: none;
          color: #137547;
          transition: 0.2s ease-in-out;
          :hover {
            color: #000000cc;
          }
        }
        .priceCategoryWrapper {
          display: flex;
          justify-content: space-between;
          .categoryTag {
            border-radius: 2px;
            font-weight: 500;
            color: #137547;
            font-size: 0.7rem;
            i {
              color: #137547;
            }
          }
          .priceTag {
            font-weight: 500;
            font-size: 0.7rem;
            color: #137547;
            i {
              color: #137547;
            }
          }
        }
      }
    }
  }
}
.categoriesTitle {
  text-align: center;
  margin: 1rem auto 0 auto;
  color: #137547;
  i {
    color: #137547;
  }
}
.categoryWrapper {
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  overflow-x: scroll;
  .categorySlider {
    display: flex;
    flex-direction: row;
    .allCategories {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #137547;
      h3 {
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
          margin-right: 0.5rem;
        }
      }
    }
    .categoryCard {
      margin: 1rem 0.5rem;
      width: 250px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
      border: 1px solid rgb(220, 220, 220);
      .categoryName {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        background-color: #137547;
        color: #fff;
        h2 {
          font-weight: 400;
          font-size: 1.2rem;
        }
      }
      .categoryListWrapper {
        .categoryList {
          margin: 0.5rem;
          display: block;
          text-decoration: none;
          color: #000;
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          transition: 0.1s ease-in-out;
          &:hover {
            color: #137547;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1140px) {
  .heroText {
    display: flex;
    flex-direction: row;
    align-items: center;
    .search {
      padding: 2rem;
    }
    .heroDescription {
      width: 700px;
      text-align: justify;
      h1 {
        text-align: center;
        font-size: 3rem;
      }
    }
  }
  .contentWrapper {
    margin: 0 auto;
    background-color: #fff;
    .newItemsWrapper {
      justify-content: space-evenly;
      .itemCard {
        margin: 1rem auto;
        width: 300px;
        height: 400px;
        flex-direction: column;
        .itemDetails {
          .priceCategoryWrapper {
            .categoryTag {
              font-size: 1rem;
            }
            .priceTag {
              font-size: 1rem;
            }
          }
        }
        img {
          max-height: 60%;

          width: 100%;
        }
        .itemDetails {
          height: 100%;
        }
      }
    }
  }
  .categoryWrapper {
    justify-content: space-around;
  }
}
::-webkit-scrollbar {
  width: 25px;
}
</style>
