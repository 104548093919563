<template>
  <div class="foo">
    <div class="contentWrapper">
      <div class="first">
        <h2>Kontakt</h2>
        <p>PSSS Niš d.o.o</p>
        <p>Niš tel.<a class="footerInfoParagraph" href="teL:+381 (0)18 26 49 32">+381 (0)18 26 49 32</a></p>
        <p>Mobile: <a class="footerInfoParagraph" href="teL:+381 (0)64 84 35 509">+381 (0)64 84 35 509</a></p>
        <p>Mail: <a class="footerInfoParagraph" href="mailto:psssnis@eunet.rs">psssnis@eunet.rs</a></p>
        <img src="../Assets/footer-logo.png" alt="" />
      </div>
      <div class="third">
        <p>Projekat je finansiran od strane</p>
        <p><a class="footerInfoParagraph" href="http://www.minpolj.gov.rs/">Ministarstva poljoprivrede, šumarstva i vodoprivrede</a></p>
      </div>
      <div class="second">
        <h2>Mreže</h2>
        <div class="iconWrapper">
          <div href="#" class="icon"><v-icon large color="white">mdi-facebook</v-icon></div>
          <div href="#" class="icon"><v-icon large color="white">mdi-instagram</v-icon></div>
          <div href="#" class="icon"><v-icon large color="white">mdi-twitter</v-icon></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footerInfoParagraph {
  color: #fff;
  text-decoration: none;
}
.foo {
  bottom: 0 !important;
  right: 0;
  left: 0;
  margin-top: 2rem;
  background-color: #137547;
  color: whitesmoke;
  text-align: left;
  padding-bottom: 55px !important;
  .contentWrapper {
    h2 {
      text-align: left;
    }
    .first {
      display: block;
      padding: 2rem;
    }
    .second {
      .iconWrapper {
        display: flex;
        justify-content: flex-start;
      }
      .icon {
        margin: 5px;
        text-decoration: none;
      }
      display: block;
      padding: 2rem;
    }
    .third {
      display: block;
      padding: 2rem;
    }
  }
}

@media screen and (min-width: 1140px) {
  .foo {
    padding-bottom: 0 !important;
    .contentWrapper {
      margin: 0 auto;
      max-width: 1440px;
      display: flex;
      .first {
        text-align: justify;
        width: 640px;
      }
      .second {
        margin: 0;
        width: 640px;
      }
      .third {
        text-align: justify;
        width: 640px;
      }
    }
  }
}
</style>
